  <template>
  <div class="main" v-show="isshow">
    <nav :class="headerFixed?'tabFixed tablist':'tablist'" id='tab'>
      <div @click='handleSelectTab(item.id)' :class="active==item.id?'tab-item tab-active':'tab-item'" v-for='item in tabList' :key='item.id'>{{item.name}}</div>
    </nav>
    <div class="item-content">
      <div>11111111</div>
    </div>
    <div class="item-content">
      <div>22222</div>
    </div>
    <div class="item-content">
      <div>33333</div>
    </div>
    <div class="item-content">
      <div>44444</div>
    </div>
    <div class="item-content">
      <div>555555</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      active: '1',
      headerFixed: '',
      tabList: [{
        id: 1,
        name: '详情'
      }, {
        id: 2,
        name: '评论'
      }, {
        id: 3,
        name: '新闻'
      }, {
        id: 4,
        name: '关于'
      }, {
        id: 5,
        name: '相关'
      }]
    }
  },
  created () {
    if (!document.cookie.includes('ad=')) {
      this.setcookie()
      this.isshow = true
    } else {
      this.isshow = false
    }
  },
  methods: {
    handleScroll () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      this.headerFixed = scrollTop > this.offsetTop
      for (let i = 0; i < this.arrDom.length; i++) {
        // 因为下面使用到了i+1，所以需要把最后一个分离出来判断
        if (this.arrDom[this.arrDom.length - 1].offsetTop - scrollTop > 80) {
          if (this.arrDom[i].offsetTop - scrollTop <= 80 && this.arrDom[i + 1].offsetTop - scrollTop > 80) {
            this.active = i + 1
          }
        } else {
          this.active = this.arrDom.length
        }
      }
    }
  },
  mounted () {
    // 记录每个内容对用的dom数组
    this.arrDom = document.getElementsByClassName('item-content')
    window.addEventListener('scroll', this.handleScroll)
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  }

}
</script>
<style lang="scss" scoped>
.main {
  width: 100%;
  height: 100vh !important;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  .sec {
    width: 80%;
    height: 500px;
    margin: 0.9rem auto;
    img {
      width: 100%;
      height: 400px;
    }
    .dele {
      width: 50px;
      height: 50px;
      margin: 30px auto;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
